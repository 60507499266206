import { Grid, Container } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";

import { Link } from "react-router-dom";
import logo from "assets/img/logo_final.png";

import "./style.scss";

const Footer = () => {
  return (
    <div className="footer">
      <Container maxWidth="lg" className="container">
        <Grid container>
          <Grid item xs={12} md={4}>
            <img src={logo} alt="logo" className="logo_footer" />
          </Grid>
          <Grid item xs={12} md={8}>
            <h3>Contact</h3>
            <p className="details">
              <HomeIcon />{" "}
              <span>
                {" "}
                Immeuble Ranohisoa Làlana Ravoninahitriniarivo Route des
                Hydrocarbures Ankorondrano 101 Antananarivo
              </span>
            </p>
            <p className="details">
              <CallIcon /> <span>+261 38 08 315 13</span>
            </p>
            <p className="details">
              <FacebookIcon />
              <a
                href="https://www.facebook.com/autonomierurale"
                target="_blank"
                style={{ textDecoration: "none", color: "white" }}
              >
                Cedar
              </a>
            </p>
            <p className="details">
              <MailIcon />
              <span>
                <Link to="mailto:cedar.evm@gmail.com">cedar.evm@gmail.com</Link>
              </span>
            </p>
          </Grid>
        </Grid>
      </Container>
      <div className="copyright">&copy; 2022</div>
    </div>
  );
};

export default Footer;
