import axios from "axios";
import { useReducer } from "react";

const MAIN_URL = "https://admin.cedar-evm.org/api";

const API = axios.create({
  baseURL: MAIN_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const useFetch = (url) => {
  const init = {
    data: [],
    loading: true,
    error: false,
    success: false,
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "FETCHING":
        return { ...init, loading: true };
      case "FETCHED":
        return { ...init, loading: false, data: action.payload, success: true };
      case "FETCH_ERROR":
        return {
          ...init,
          loading: false,
          error: action.payload,
          success: false,
        };
      case "INIT":
        return { ...init, data: action.payload, success: false };
      default:
        return state;
    }
  }, init);

  let source = axios.CancelToken.source();

  const load = async (props = {}) => {
    const { data, methode, params } = props;

    dispatch({ type: "FETCHING" });

    let opt = {
      ...(data && { data: data }),
      ...(methode && { methode: methode }),
      ...(params && { params: params }),
      cancelToken: source.token,
      responseType: "json",
    };

    return await API(url, opt)
      .then((resp) => {
        dispatch({ type: "FETCHED", payload: resp.data });
        return resp.data;
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          dispatch({ type: "FETCH_ERROR", payload: err?.response?.data });
          console.error(err);
        }
        return false;
      });
  };

  return [state, load, source];
};

export default useFetch;
