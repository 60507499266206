import { Box, Card, Skeleton } from "@mui/material";
import React from "react";

const ContentLoader = ({ size = 1 }) => {
  return Array.from({ length: size })
    .fill("_")
    .map((_, key) => (
      <Card sx={{ maxWidth: 270 }} key={key}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={140}
          animation="wave"
        />
        <Box px={2} pt={2}>
          <Skeleton variant="text" width={160} animation="wave" />
          <Skeleton variant="text" width={80} animation="wave" />
        </Box>
        <Box p={2}>
          <Skeleton variant="text" width={250} animation="wave" />
          <Skeleton variant="text" width={250} animation="wave" />
          <Skeleton variant="text" width={70} animation="wave" />
        </Box>
      </Card>
    ));
};

export default ContentLoader;
