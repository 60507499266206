import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import no_image from "assets/img/no-image.jpg";

const ActionAreaCard = ({ item }) => {
  let navigate = useNavigate();

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea
        onClick={() =>
          navigate(`/${item.type}/${item?.slug}`, { replace: true })
        }
      >
        <CardMedia
          component="img"
          height="140"
          image={item?.image || no_image}
          alt="Image"
        />
        <CardContent>
          <p>
            <b>{item?.titre || "Aucun titre"}</b>
          </p>
          {/* <p className="">{item?.resume || "Aucun contenu"}</p> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ActionAreaCard;
