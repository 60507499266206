import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import { Container, Box } from "@mui/material";
import Masonry from "@mui/lab/Masonry";
import Skeleton from "@mui/material/Skeleton";

import "./style.scss";

import useFetch from "hooks/useFetch";
import ActionAreaCard from "../../components/ActionAreaCard";
import Pagination from "@mui/material/Pagination";
import ContentLoader from "../../components/ContentLoader";

const Articles = ({ url, title }) => {
  const [actu, getActu, cancelGetActu] = useFetch(url || "actualite");

  useEffect(() => {
    getActu();
    return () => {
      cancelGetActu.cancel();
    };
  }, [url]);

  const { data } = actu.data;

  const handlePage = (item, value) => {
    getActu({ params: { page: value } });
  };

  return (
    <div className="articles">
      <Container maxWidth="lg">
        <h2>{title || "Actualités"}</h2>
        {!actu.loading ? (
          <>
            <Masonry columns={{ xs: 1, sm: 2, md: 4 }} spacing={2}>
              {data ? (
                data.map((item, key) => (
                  <ActionAreaCard key={key} item={item} />
                ))
              ) : (
                <></>
              )}
            </Masonry>
            <Pagination
              count={Number(actu.data?.last_page) || 1}
              onChange={handlePage}
              color="primary"
            />
          </>
        ) : (
          <Masonry columns={{ xs: 1, sm: 2, md: 4 }} spacing={2}>
            <ContentLoader size={3} />
          </Masonry>
        )}
      </Container>
    </div>
  );
};

export default Articles;

const ArticleLoader = () => (
  <Card sx={{ maxWidth: 345 }}>
    <Skeleton
      variant="rectangular"
      width="100%"
      height={140}
      animation="wave"
    />
    <Box px={2} pt={2}>
      <Skeleton variant="text" width={100} animation="wave" />
    </Box>
    <Box p={2}>
      <Skeleton
        width="100%"
        variant="rectangular"
        height={80}
        animation="wave"
        sx={{ borderRadius: 1 }}
      />
    </Box>
  </Card>
);
