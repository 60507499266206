import SourceSansProExtraLight from "assets/fonts/SourceSansPro-ExtraLight.ttf";
import SourceSansProExtraLightItalic from "assets/fonts/SourceSansPro-ExtraLightItalic.ttf";
import SourceSansProLight from "assets/fonts/SourceSansPro-Light.ttf";
import SourceSansProLightItalic from "assets/fonts/SourceSansPro-LightItalic.ttf";
import SourceSansProRegular from "assets/fonts/SourceSansPro-Regular.ttf";
import SourceSansProItalic from "assets/fonts/SourceSansPro-Italic.ttf";
import SourceSansProSemiBold from "assets/fonts/SourceSansPro-SemiBold.ttf";
import SourceSansProSemiBoldItalic from "assets/fonts/SourceSansPro-SemiBoldItalic.ttf";
import SourceSansProBold from "assets/fonts/SourceSansPro-Bold.ttf";
import SourceSansProBoldItalic from "assets/fonts/SourceSansPro-BoldItalic.ttf";
import SourceSansProBoldBlack from "assets/fonts/SourceSansPro-Black.ttf";
import SourceSansProBoldBlackItalic from "assets/fonts/SourceSansPro-BlackItalic.ttf";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  styleOverrides: `
    @font-face {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 200;
        src: url('${SourceSansProExtraLight}') format('truetype');
    }
        @font-face {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: italic;
        font-weight: 200;
        src: url('${SourceSansProExtraLightItalic}') format('truetype');
    }
        @font-face {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 300;
        src: url('${SourceSansProLight}') format('truetype');
    }
        @font-face {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: italic;
        font-weight: 300;
        src: url('${SourceSansProLightItalic}') format('truetype');
    }
        @font-face {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        src: url('${SourceSansProRegular}') format('truetype');
    }
        @font-face {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: italic;
        font-weight: 400;
        src: url('${SourceSansProItalic}') format('truetype');
    }
        @font-face {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        src: url('${SourceSansProSemiBold}') format('truetype');
    }
        @font-face {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: italic;
        font-weight: 600;
        src: url('${SourceSansProSemiBoldItalic}') format('truetype');
    }
        @font-face {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 700;
        src: url('${SourceSansProBold}') format('truetype');
    }
        @font-face {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: italic;
        font-weight: 700;
        src: url('${SourceSansProBoldItalic}') format('truetype');
    }
        @font-face {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 900;
        src: url('${SourceSansProBoldBlack}') format('truetype');
    }
        @font-face {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: italic;
        font-weight: 900;
        src: url('${SourceSansProBoldBlackItalic}') format('truetype');
    }
    `,
};
