import { useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Skeleton, Box, IconButton, Divider } from "@mui/material";
import parse from "html-react-parser";

import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import simple_bg from "assets/img/simple_bg.svg";
import useFetch from "hooks/useFetch";
import "./style.scss";

const Impact = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let topRef = useRef();

  const [impact, getImpact, cancelGetImpact] = useFetch(location.pathname);

  useEffect(() => {
    getImpact();

    return () => {
      cancelGetImpact.cancel();
    };
  }, [location.pathname]);

  const handleClick = (navigateTo) => {
    navigate(`/impact/${navigateTo}`);
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="article">
      <div className="article_bg" ref={topRef}>
        {impact.loading ? (
          <img src={simple_bg} alt="" />
        ) : impact.data?.infos?.image ? (
          <img src={impact.data?.infos?.image} alt="" />
        ) : (
          <img src={simple_bg} alt="" />
        )}
      </div>
      <div className="article_container">
        <Container>
          {impact.loading ? (
            <Box pt={14} pb={6}>
              <Skeleton
                variant="rectangular"
                width={300}
                animation="wave"
                height={50}
                sx={{ borderRadius: 1 }}
              />
            </Box>
          ) : (
            <h2 className="article_title">
              {impact.data?.infos?.titre || "Aucun titre"}
            </h2>
          )}
          <div className="article_content">
            <Box display="flex" justifyContent="flex-end">
              {impact.data?.infos?.fichier && (
                <IconButton
                  color="warning"
                  component="a"
                  href={impact.data?.infos?.fichier}
                  target="_blank"
                >
                  <PictureAsPdfIcon fontSize="large" />
                </IconButton>
              )}
            </Box>

            <Divider />
            {impact.loading ? (
              <Box pt={3} pb={3}>
                <Skeleton variant="text" width={300} animation="wave" />
              </Box>
            ) : (
              <h3>{impact.data?.infos?.titre || "Aucun titre"}</h3>
            )}

            {impact.loading ? (
              <Skeleton
                variant="rectangular"
                width={"100%"}
                animation="wave"
                height={400}
                sx={{ borderRadius: 1 }}
              />
            ) : (
              <div>
                {parse(impact.data?.infos?.description || "Aucune description")}
              </div>
            )}
          </div>
          <div className="other_article">
            {impact.data?.prev === null ||
            typeof impact.data?.prev === "undefined" ? (
              <span></span>
            ) : (
              <button
                className="btn_navigate"
                onClick={() => handleClick(impact.data?.prev?.slug)}
              >
                <span className="title_navigate">
                  <KeyboardDoubleArrowLeftIcon />
                  <span> Précédent </span>
                </span>
                <span className="link_navigate">
                  {impact.data?.prev?.titre || "Titre Précedent"}
                </span>
              </button>
            )}

            {impact.data?.next === null ||
            typeof impact.data?.next === "undefined" ? (
              <span></span>
            ) : (
              <button
                className="btn_navigate"
                onClick={() => handleClick(impact.data?.next?.slug)}
              >
                <span className="title_navigate">
                  <span> Suivant</span>
                  <KeyboardDoubleArrowRightIcon />
                </span>
                <span className="link_navigate">
                  {impact.data?.next?.titre || "Titre Suivant"}
                </span>
              </button>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Impact;
