import { useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Skeleton, Box, IconButton, Divider } from "@mui/material";
import parse from "html-react-parser";

import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import useFetch from "hooks/useFetch";
import "./style.scss";

const Connaissance = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let topRef = useRef();

  const [connaissance, getConnaissance, cancelGetConnaissance] = useFetch(location.pathname);

  useEffect(() => {
    getConnaissance();

    return () => {
      cancelGetConnaissance.cancel();
    };
  }, [location.pathname]);

  const handleClick = (navigateTo) => {
    navigate(`/connaissance/${navigateTo}`);
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="article">
      <div className="article_bg" ref={topRef}>
        <img src={connaissance.data?.infos?.image} alt="" />
      </div>
      <div className="article_container">
        <Container>
          {connaissance.loading ? (
            <Box pt={14} pb={6}>
              <Skeleton
                variant="rectangular"
                width={300}
                animation="wave"
                height={50}
                sx={{ borderRadius: 1 }}
              />
            </Box>
          ) : (
            <h2 className="article_title">
              {connaissance.data?.infos?.titre || "Aucun titre"}
            </h2>
          )}
          <div className="article_content">

            <Box display="flex" justifyContent="flex-end">

              {connaissance.data?.infos?.fichier && (
                <IconButton color="warning" component="a" href={connaissance.data?.infos?.fichier} target="_blank">
                  <PictureAsPdfIcon />
                </IconButton>
              )}

            </Box>

            <Divider />
            {connaissance.loading ? (
              <Box pt={3} pb={3}>
                <Skeleton variant="text" width={300} animation="wave" />
              </Box>
            ) : (
              <h3>{connaissance.data?.infos?.titre || "Aucun titre"}</h3>
            )}

            {connaissance.loading ? (
              <Skeleton
                variant="rectangular"
                width={"100%"}
                animation="wave"
                height={400}
                sx={{ borderRadius: 1 }}
              />
            ) : (
              <div>
                {parse(connaissance.data?.infos?.description || "Aucune description")}
              </div>
            )}

          </div>
          <div className="other_article">
            {connaissance.data?.prev === null ||
            typeof connaissance.data?.prev === "undefined" ? (
              <span></span>
            ) : (
              <button
                className="btn_navigate"
                onClick={() => handleClick(connaissance.data?.prev?.slug)}
              >
                <span className="title_navigate">
                  <KeyboardDoubleArrowLeftIcon />
                  <span> Précédent </span>
                </span>
                <span className="link_navigate">
                  {connaissance.data?.prev?.titre || "Titre Précedent"}
                </span>
              </button>
            )}

            {connaissance.data?.next === null ||
            typeof connaissance.data?.next === "undefined" ? (
              <span></span>
            ) : (
              <button
                className="btn_navigate"
                onClick={() => handleClick(connaissance.data?.next?.slug)}
              >
                <span className="title_navigate">
                  <span> Suivant</span>
                  <KeyboardDoubleArrowRightIcon />
                </span>
                <span className="link_navigate">
                  {connaissance.data?.next?.titre || "Titre Suivant"}
                </span>
              </button>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Connaissance;
