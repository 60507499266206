import { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import loadable from "@loadable/component";

//All pages
// import Home from "pages/Home";
import Article from "pages/Article";
import Articles from "pages/Articles";
import Connaissances from "pages/Connaissance";
import Connaissance from "pages/Connaissance/info";
import Impacts from "pages/Impact";
import Impact from "pages/Impact/info";
import Autre from "pages/Autre";
import Autres from "pages/Autre/list";
import Centre from "pages/Centre";

//Compo
import NotFound from "components/NotFound";

//style
import "./style.scss";

const LoadablePage = loadable((props) => import(`pages/${props.page}`), {
  fallback: <div>Page is Loading...</div>,
  cacheKey: (props) => props.page,
});

const RouteWay = () => {
  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);

  return (
    <main className={`route_way`}>
      <div
        className={`${transitionStage}`}
        onAnimationEnd={() => {
          if (transitionStage === "fadeOut") {
            setTransistionStage("fadeIn");
            setDisplayLocation(location);
          }
        }}
      >
        <Routes location={displayLocation}>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<LoadablePage page="Home" />} />
          <Route
            path="actualites"
            element={<Articles title="Actualités" url="actualite" />}
          />
          <Route
            path="innovation"
            element={<Articles title="Innovations" url="all-innovation" />}
          />
          <Route
            path="histoire"
            element={<Articles title="Petites histoires" url="all-histoire" />}
          />
          <Route
            path="lecon"
            element={<Articles title=" Leçons apprises" url="all-lecon" />}
          />
          <Route path="impact" element={<Impacts />} />
          <Route path="opportunite" element={<Impacts />} />
          <Route path="connaissances" element={<Connaissances />} />
          <Route path="actualite">
            <Route path=":id" element={<Article />} />
          </Route>
          <Route path="innovation">
            <Route path=":id" element={<Article />} />
          </Route>
          <Route path="lecon">
            <Route path=":id" element={<Article />} />
          </Route>
          <Route path="histoire">
            <Route path=":id" element={<Article />} />
          </Route>
          <Route path="connaissance">
            <Route path=":id" element={<Connaissance />} />
          </Route>
          <Route path="impact">
            <Route path=":id" element={<Impact />} />
          </Route>
          <Route path="autres">
            <Route path=":id" element={<Autres />} />
          </Route>
          <Route path="autre">
            <Route path=":id" element={<Autre />} />
          </Route>
          <Route path="centre" element={<Centre />} />
          <Route path="centre">
            <Route path=":id" element={<Centre />} />
          </Route>
        </Routes>
      </div>
    </main>
  );
};

export default RouteWay;
