import { useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Skeleton, Box, IconButton, Divider } from "@mui/material";
import parse from "html-react-parser";

import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import LinkIcon from "@mui/icons-material/Link";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import useFetch from "hooks/useFetch";

import simple_bg from "assets/img/simple_bg.svg";
import "./style.scss";
import Grid from "@mui/material/Grid";

const Article = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let topRef = useRef();

  const [actu, getActu, cancelGetActu] = useFetch(location.pathname);

  useEffect(() => {
    getActu();

    return () => {
      cancelGetActu.cancel();
    };
  }, [location.pathname]);

  const handleClick = (navigateTo) => {
    navigate(`/${actu.data?.infos?.type}/${navigateTo}`);
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="article">
      <div className="article_bg" ref={topRef}>
        {actu.loading ? (
          <img src={simple_bg} alt="" />
        ) : (
          <img src={actu.data?.infos?.image} alt="" />
        )}
      </div>
      <div className="article_container">
        <Container>
          <div style={{ minHeight: 400 }}></div>
          <div className="article_content">
            <Box display="flex" justifyContent="flex-end">
              {actu.data?.infos?.fichier && (
                <IconButton
                  color="warning"
                  component="a"
                  href={actu.data?.infos?.fichier}
                  target="_blank"
                >
                  <PictureAsPdfIcon fontSize="large" />
                </IconButton>
              )}
            </Box>

            {/* <Divider /> */}

            <Grid container>
              <Grid item sm={12} md={8}>
                {actu.loading ? (
                  <Box pt={14} pb={6}>
                    <Skeleton
                      variant="rectangular"
                      width={300}
                      animation="wave"
                      height={50}
                      sx={{ borderRadius: 1 }}
                    />
                  </Box>
                ) : (
                  <h1 /* className="article_title" */>
                    {actu.data?.infos?.titre || "Aucun titre"}
                  </h1>
                )}
                {actu.loading ? (
                  <Box pt={3} pb={3}>
                    <Skeleton variant="text" width={300} animation="wave" />
                  </Box>
                ) : (
                  <h5>{actu.data?.infos?.resume || "Aucun resume"}</h5>
                )}

                {actu.loading ? (
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    animation="wave"
                    height={400}
                    sx={{ borderRadius: 1 }}
                  />
                ) : (
                  <div>
                    {parse(actu.data?.infos?.description || "Aucun contenu")}
                  </div>
                )}
              </Grid>

              <Grid
                item
                sm={12}
                md={4}
                display="flex"
                alignItems="flex-end"
                flexDirection="column"
                gap={1}
              >
                {actu.data?.infos?.image && (
                  <img
                    src={actu.data?.infos?.image}
                    alt="Image"
                    width="300px"
                  />
                )}

                {actu.data?.infos?.lien && (
                  <iframe
                    id="videoFile"
                    width="350px"
                    height="200px"
                    src={actu.data?.infos?.lien}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                )}
              </Grid>
            </Grid>
          </div>
          <div className="other_article">
            {actu.data?.prev === null ||
            typeof actu.data?.prev === "undefined" ? (
              <span></span>
            ) : (
              <button
                className="btn_navigate"
                onClick={() => handleClick(actu.data?.prev?.slug)}
              >
                <span className="title_navigate">
                  <KeyboardDoubleArrowLeftIcon />
                  <span> Précédent </span>
                </span>
                <span className="link_navigate">
                  {actu.data?.prev?.titre || "Titre Précedent"}
                </span>
              </button>
            )}

            {actu.data?.next === null ||
            typeof actu.data?.next === "undefined" ? (
              <span></span>
            ) : (
              <button
                className="btn_navigate"
                onClick={() => handleClick(actu.data?.next?.slug)}
              >
                <span className="title_navigate">
                  <span> Suivant</span>
                  <KeyboardDoubleArrowRightIcon />
                </span>
                <span className="link_navigate">
                  {actu.data?.next?.titre || "Titre Suivant"}
                </span>
              </button>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Article;
