import React, { useEffect } from "react";
import { Skeleton, Box, Typography, Chip, Divider } from "@mui/material";
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";
import parse from "html-react-parser";

const About = ({ loading, selectCentre, screenTooSmall }) => {
  useEffect(() => {
    if (!loading) {
      new Viewer(document.querySelector(".gallery_lightbox"), {
        toolbar: false,
      });
    }
  }, [loading]);

  if (loading) {
    return <AboutLoading />;
  } else {
    return (
      <>
        <Box>
          <Typography
            variant={screenTooSmall ? "h3" : "h4"}
            component="span"
            fontWeight={700}
          >
            {`${selectCentre?.nom || "Aucun nom"}`}
          </Typography>

          <Box pb={2} display="flex" gap={1}>
            {selectCentre?.surface && (
              <Chip
                label={`Surface ${selectCentre?.surface || "0"}`}
                color="primary"
              />
            )}
            <Chip
              label={`${selectCentre?.localisation || "aucune"}`}
              color="primary"
              variant="outlined"
            />
          </Box>
          {/* <Typography
            variant={screenTooSmall ? "h2" : "h4"}
            component="h2"
            fontWeight={700}
          >
            {`${selectCentre?.presentation || "aucun"}`}
          </Typography> */}

          <img
            src={selectCentre?.lien}
            alt=""
            width={screenTooSmall ? 500 : 300}
            className="gallery_lightbox"
          />

          <Typography variant="body1" component="p">
            {parse(`${selectCentre?.objectif || "aucun"}`)}
          </Typography>
        </Box>

        {/* <Paper>
                <Box p={2}>
                  <Typography
                    variant="h6"
                    component="h4"
                    fontWeight={700}
                    color="primary"
                    mb={2}
                  >
                    Objectif
                  </Typography>
                  <Typography variant="body2" component="p">
                    {`${selectCentre?.objectif || "aucun"}`}
                  </Typography>
                </Box>
              </Paper> */}
        <Box py={5}>
          <Divider />
        </Box>

        <Box mb={20}>
          <Typography
            variant="h6"
            component="h4"
            fontWeight={700}
            color="primary"
            mb={2}
          >
            Vision
          </Typography>
          <Typography variant="body1" component="p">
            {parse(`${selectCentre?.vision || "aucun"}`)}
          </Typography>
        </Box>
      </>
    );
  }
};

export default About;

const AboutLoading = () => {
  const defaultAttr = {
    variant: "rectangular",
    animation: "wave",
  };
  const attrChip = {
    ...defaultAttr,
    width: 100,
    height: 32,
    sx: { borderRadius: 25 },
  };

  return (
    <div>
      <Box py={10}>
        <Box display="flex" gap={1}>
          <Skeleton {...attrChip} />
          <Skeleton {...attrChip} />
        </Box>

        <Skeleton
          {...defaultAttr}
          width="50%"
          height={72}
          sx={{ borderRadius: 1, marginTop: 2 }}
        />
        <Skeleton
          {...defaultAttr}
          width="100%"
          height={24}
          sx={{ borderRadius: 1, marginTop: 2 }}
        />
      </Box>

      <Box pb={2}>
        <Skeleton
          {...defaultAttr}
          width="50%"
          height={32}
          sx={{ borderRadius: 1 }}
        />
      </Box>
      <Skeleton
        {...defaultAttr}
        width="100%"
        height={100}
        sx={{ borderRadius: 1, marginTop: 2 }}
      />
    </div>
  );
};
