import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Container, Box, Hidden, Button } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import { blue } from "@mui/material/colors";
import MenuItem from "@mui/material/MenuItem";

import useFetch from "hooks/useFetch";

import logo_cedar from "assets/img/logo_final.png";
import logo_evm from "assets/img/logo_evm.png";

import "./style.scss";
import Menu from "@mui/material/Menu";

const menuList = [
  { label: "Accueil", link: "/", child: [""] },
  {
    label: "Actualités",
    link: "#",
    child: ["actualite"],
    sub: [
      { label: "Innovations", link: "/innovation", child: ["innovation"] },
      { label: "Petites histoires", link: "/histoire", child: ["histoire"] },
      { label: "Leçons apprises", link: "/lecon", child: ["lecon"] },
      { label: "Actualités", link: "/actualites", child: ["actualite"] },
    ],
  },
  { label: "Centres", link: "/centre", child: ["centre"] },
  {
    label: "Bases de connaissances",
    link: "/connaissances",
    child: "connaissances",
  },
  { label: "Impacts", link: "/impact", child: ["impact"] },
];

const Header = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const [menu, setMenu] = useState("/");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hasScroll, setHasScroll] = useState(false);
  const [open, setOpen] = useState(null);

  const [extendListMenu, getExtendListMenu, cancelExtListMenu] =
    useFetch("all-categorie");

  const handleScroll = () => {
    setHasScroll(parseInt(window.scrollY) > 0);
  };

  const handleChange = (value) => {
    setMenu(value);
    navigate(value);
  };

  const handleDropDown = (event, key) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(key);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  useEffect(() => {
    let _path = location.pathname.split("/")[1];
    for (let item of menuList) {
      if (item.child.includes(_path)) {
        setMenu(item.link);
        break;
      }
    }
  }, [location]);

  useEffect(() => {
    getExtendListMenu({}).then((data) => {
      if (data.length == 0) return;

      let subMenus = [];

      for (let item of data) {
        subMenus.push({
          label: item.titre,
          link: "/autres/" + item.id,
          child: ["opportunite"],
        });
      }

      let itemMenu = {
        label: "Opportunité",
        link: "#",
        child: ["opportunite"],
        sub: subMenus,
      };

      menuList.push(itemMenu);
    });
    return () => {
      cancelExtListMenu.cancel();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="header">
      <div className="header-title">
        <Container maxWidth="lg">
          <div className="header-content">
            <Link to="/">
              <img
                src={logo_cedar}
                alt="logo"
                className={hasScroll ? "small-image" : ""}
              />
            </Link>
            <Hidden mdDown>
              <span className={`slogan ${hasScroll ? "small-texte" : ""}`}>
                Centre d’Entraînement au Développement et à l’Autonomie Rurale
              </span>
            </Hidden>
            <span>
              <img
                src={logo_evm}
                alt="logo_evm"
                className={hasScroll ? "small-image" : ""}
              />
            </span>
          </div>
          <div className="slogan">
            <Hidden mdUp>
              Centre d’Entraînement au Développement et à l’Autonomie Rurale
            </Hidden>
          </div>
        </Container>
      </div>
      <div className="header-menu">
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center">
            {menuList.map((item, key) => (
              <span key={key}>
                <Button
                  sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                  onClick={(ev) => {
                    if (item.link !== "#") {
                      handleChange(item.link);
                    } else {
                      handleDropDown(ev, key);
                    }
                  }}
                  color={menu == item.link ? "secondary" : ""}
                  variant={menu == item.link ? "outlined" : ""}
                >
                  {item.label}
                </Button>

                {item?.sub && (
                  <Menu
                    anchorEl={anchorEl}
                    open={open == key}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {item.sub.map((itemSub, keySub) => (
                      <MenuItem
                        key={keySub}
                        onClick={() => {
                          handleChange(itemSub.link);
                          handleClose();
                        }}
                      >
                        {itemSub.label}
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              </span>
            ))}

            {/* <nav className="header-menu-nav">
            <Tabs
              value={menu}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {menuList.map((item, key) => (
                <StyledTab label={item.label} value={item.link} key={key} />
              ))}
            </Tabs> */}
            <a
              href="https://www.facebook.com/autonomierurale"
              target="_blank"
              style={{ marginLeft: "auto" }}
            >
              <FacebookIcon sx={{ color: blue[400], fontSize: 40 }} />
            </a>
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default Header;
