import React, { useEffect } from "react";
import useFetch from "hooks/useFetch";
import { Container } from "@mui/material";
import Masonry from "@mui/lab/Masonry";
import ActionAreaCard from "../../components/ActionAreaCard";
import Pagination from "@mui/material/Pagination";
import ContentLoader from "../../components/ContentLoader";

const Histoire = ({ idCentre }) => {
  const [datas, getData, esc] = useFetch(`centre/${idCentre}/histoire`);

  useEffect(() => {
    getData();
    return () => {
      esc.cancel();
    };
  }, [idCentre]);

  const { data } = datas.data;
  const handlePage = (item, value) => {
    getData({ params: { page: value } });
  };

  return (
    <div className="articles">
      <Container maxWidth="lg">
        <h2>{"Histoires"}</h2>
        {!datas.loading ? (
          <>
            <Masonry columns={{ xs: 1, sm: 2, md: 4 }} spacing={2}>
              {data ? (
                data.map((item, key) => (
                  <ActionAreaCard key={key} item={item} />
                ))
              ) : (
                <></>
              )}
            </Masonry>
            <Pagination
              count={Number(datas.data?.last_page) || 1}
              onChange={handlePage}
              color="primary"
            />
          </>
        ) : (
          <ContentLoader />
        )}
      </Container>
    </div>
  );
};

export default Histoire;
