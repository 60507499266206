import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Container, Box } from "@mui/material";
import Masonry from "@mui/lab/Masonry";
import Skeleton from "@mui/material/Skeleton";

import "./style.scss";

import useFetch from "hooks/useFetch";

const Autres = () => {
  let location = useLocation();
  let navigate = useNavigate();

  let ids =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  const [autres, getAutres, cancelGetAutres] = useFetch(
    `/get-opportunite/${ids}`
  );

  useEffect(() => {
    getAutres();
    return () => {
      cancelGetAutres.cancel();
    };
  }, [ids]);

  const { data, titre } = autres.data;

  return (
    <div className="articles">
      <Container maxWidth="lg">
        <h2>{titre || "Autres"}</h2>
        <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
          {data ? (
            data.map((item, key) => <ActionAreaCard key={key} item={item} />)
          ) : (
            <></>
          )}
        </Masonry>
      </Container>
    </div>
  );
};

export default Autres;

const ActionAreaCard = ({ item }) => {
  let navigate = useNavigate();

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea
        onClick={() => navigate(`/autre/${item.slug}`, { replace: true })}
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {item.titre || "Aucun titre"}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const ImpactLoader = () => (
  <Card sx={{ maxWidth: 345 }}>
    <Skeleton
      variant="rectangular"
      width="100%"
      height={140}
      animation="wave"
    />
    <Box px={2} pt={2}>
      <Skeleton variant="text" width={100} animation="wave" />
    </Box>
    <Box p={2}>
      <Skeleton
        width="100%"
        variant="rectangular"
        height={80}
        animation="wave"
        sx={{ borderRadius: 1 }}
      />
    </Box>
  </Card>
);
