import { createTheme } from "@mui/material/styles";
import font from "./font";

const defaultPalette = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "rgb(56, 102, 61)",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#cfd420",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    ternary: {
      main: "#9b3e74",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: "'Source Sans Pro', sans-serif",
  },
  components: {
    MuiCssBaseline: font,
  },
});

export default createTheme(defaultPalette, {});
