import Footer from "components/Footer";
import Header from "components/Header";
import { BrowserRouter } from "react-router-dom";
import RouteWay from "RouteWay";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <RouteWay />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
