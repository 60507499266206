import React, { useEffect } from "react";
import useFetch from "hooks/useFetch";
import { Container } from "@mui/material";
import Masonry from "@mui/lab/Masonry";
import no_image from "../../assets/img/no-image.jpg";
import CardMedia from "@mui/material/CardMedia";
import Pagination from "@mui/material/Pagination";
import ContentLoader from "../../components/ContentLoader";

import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";

const Gallery = ({ idCentre }) => {
  const [datas, getData, esc] = useFetch(`centre/${idCentre}/gallery`);

  useEffect(() => {
    getData();

    return () => {
      esc.cancel();
    };
  }, [idCentre]);

  useEffect(() => {
    if (!datas.loading) {
      const viewer = new Viewer(document.querySelector(".gallery_lightbox"), {
        toolbar: {
          prev: true,
          next: true,
        },
      });
    }
  }, [datas.loading]);

  const { data } = datas.data;
  const handlePage = (item, value) => {
    getData({ params: { page: value } });
  };

  return (
    <div className="articles">
      <Container maxWidth="lg">
        <h2>{"Galleries"}</h2>
        {!datas.loading ? (
          <>
            <Masonry
              columns={{ xs: 1, sm: 2, md: 4 }}
              spacing={2}
              className="gallery_lightbox"
            >
              {data ? (
                data.map((item, key) => (
                  <CardMedia
                    component="img"
                    height="140"
                    image={item.image || no_image}
                    alt="Image"
                  />
                ))
              ) : (
                <></>
              )}
            </Masonry>
            <Pagination
              count={Number(datas.data?.last_page) || 1}
              onChange={handlePage}
              color="primary"
            />
          </>
        ) : (
          <ContentLoader />
        )}
      </Container>
    </div>
  );
};

export default Gallery;
