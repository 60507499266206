import { useState, useEffect, useMemo } from "react";
import {
  Container,
  Tab,
  Tabs,
  Skeleton,
  Box,
  Grid,
  ListItemText,
  ListItemButton,
  ListItem,
  ListItemIcon,
  List,
  useMediaQuery,
} from "@mui/material";
import {
  Info,
  Newspaper,
  MenuBook,
  Book,
  Biotech,
  BurstMode,
} from "@mui/icons-material";
import theme from "assets/theme";
import { useParams } from "react-router-dom";

import useFetch from "hooks/useFetch";

import About from "./About";
import Actualite from "./Actualite";
import Innovation from "./Innovation";
import Histoire from "./Histoire";
import Lecon from "./Lecon";
import Gallery from "./Gallery";

const Centre = () => {
  const [allcentre, getAllCentre, cancelGetCentre] = useFetch("centre");
  const [subMenu, setSubMenu] = useState(null);
  const [activeView, setActiveView] = useState("about");
  const screenTooSmall = !useMediaQuery(theme.breakpoints.down("md"));
  let params = useParams();

  const selectCentre = useMemo(
    () => allcentre.data.find((centre) => centre?.id === subMenu),
    [subMenu, allcentre.data]
  );

  const handleMenuChange = (e, value) => {
    setSubMenu(value);
  };

  useEffect(() => {
    getAllCentre().then((resp) => {
      if (resp && resp.length > 0) {
        setSubMenu(params.id ? Number(params.id) : resp[0].id);
      }
    });

    return () => {
      cancelGetCentre.cancel();
    };
  }, []);

  function viewActive(active) {
    switch (activeView) {
      case "about":
        return (
          <About
            loading={allcentre.loading}
            selectCentre={selectCentre}
            screenTooSmall={screenTooSmall}
          />
        );
      case "actu":
        return <Actualite idCentre={selectCentre.id} />;
      case "innovation":
        return <Innovation idCentre={selectCentre.id} />;
      case "histoire":
        return <Histoire idCentre={selectCentre.id} />;
      case "lecon":
        return <Lecon idCentre={selectCentre.id} />;
      case "gallery":
        return <Gallery idCentre={selectCentre.id} />;
    }
  }

  return (
    <div>
      <Container maxWidth="lg">
        <div className="sub_nav">
          {allcentre.loading ? (
            <TabLoading />
          ) : (
            <Tabs
              value={subMenu}
              onChange={handleMenuChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {allcentre.data.length > 0 &&
                allcentre.data.map((item, key) => (
                  <Tab key={key} label={item.nom} value={item.id} />
                ))}
            </Tabs>
          )}
        </div>
      </Container>
      <Container maxWidth="lg">
        <Grid container mt={5} sx={{ top: 10 }}>
          <Grid item xs={11} sm={9} pr={2}>
            {viewActive(activeView)}
          </Grid>
          <Grid item xs={1} sm={3}>
            <Box mt={15} sx={{ position: "sticky", top: 225, marginBottom: 5 }}>
              <List>
                <CtListItem
                  onClick={() => setActiveView("about")}
                  title="A propos"
                  icon={<Info color="primary" />}
                />
                <CtListItem
                  onClick={() => setActiveView("actu")}
                  title="Actualités"
                  icon={<Newspaper color="primary" />}
                />
                <CtListItem
                  onClick={() => setActiveView("lecon")}
                  title="Leçons apprises"
                  icon={<MenuBook color="primary" />}
                />
                <CtListItem
                  onClick={() => setActiveView("histoire")}
                  title="Petites histoires"
                  icon={<Book color="primary" />}
                />
                <CtListItem
                  onClick={() => setActiveView("innovation")}
                  title="Innovations"
                  icon={<Biotech color="primary" />}
                />
                <CtListItem
                  onClick={() => setActiveView("gallery")}
                  title="Banque d'images"
                  icon={<BurstMode color="primary" />}
                />
              </List>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Centre;

const TabLoading = () => {
  const attr = {
    width: 100,
    variant: "rectangular",
    height: 50,
    animation: "wave",
    sx: { borderRadius: 1, marginRight: 1 },
  };
  return (
    <>
      <Skeleton {...attr} />
      <Skeleton {...attr} />
      <Skeleton {...attr} />
    </>
  );
};

const CtListItem = (props) => {
  const screenTooSmall = !useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ListItem disablePadding sx={{ display: "block" }}>
      <ListItemButton
        onClick={props.onClick}
        sx={{
          justifyContent: screenTooSmall ? "initial" : "center",
        }}
      >
        <ListItemIcon
          sx={{
            justifyContent: "center",
          }}
        >
          {props.icon}
        </ListItemIcon>
        {screenTooSmall && <ListItemText primary={props.title || ""} />}
      </ListItemButton>
    </ListItem>
  );
};
